var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mb-4 white rounded-lg elevation-0"
  }, [_c('v-row', {
    staticClass: "px-4 pt-7"
  }, [_c('v-col', [_c('CommonAutocomplete', {
    attrs: {
      "items": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.AssignmentIds).items.value,
      "item-text": "id",
      "item-value": "id",
      "clearable": "",
      "chips": "",
      "deletable-chips": "",
      "small-chips": "",
      "outlined": "",
      "dense": "",
      "multiple": "",
      "loading": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.AssignmentIds).isLoading.value,
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.AssignmentIds).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.AssignmentIds).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.AssignmentIds), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.AssignmentIds).value"
    }
  }), !_vm.withoutPersonDropdown ? _c('CommonAutocomplete', {
    attrs: {
      "items": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.PersonIds).items.value,
      "item-text": "name",
      "item-value": "id",
      "clearable": "",
      "chips": "",
      "deletable-chips": "",
      "small-chips": "",
      "outlined": "",
      "dense": "",
      "multiple": "",
      "loading": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.PersonIds).isLoading.value,
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.PersonIds).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.PersonIds).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.PersonIds), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.PersonIds).value"
    }
  }) : _vm._e()], 1), _c('v-col', [_c('div', {
    staticClass: "d-flex"
  }, [_c('CommonDateInput', {
    staticClass: "min-width mr-5",
    attrs: {
      "outlined": "",
      "dense": "",
      "clearable": "",
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.StartFrom).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.StartFrom).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.StartFrom), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.StartFrom).value"
    }
  }), _c('CommonDateInput', {
    staticClass: "min-width",
    attrs: {
      "outlined": "",
      "dense": "",
      "clearable": "",
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.StartTo).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.StartTo).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.StartTo), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.StartTo).value"
    }
  })], 1), _c('div', {
    staticClass: "d-flex"
  }, [_c('CommonDateInput', {
    staticClass: "min-width mr-5",
    attrs: {
      "outlined": "",
      "dense": "",
      "clearable": "",
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.EndFrom).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.EndFrom).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.EndFrom), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.EndFrom).value"
    }
  }), _c('CommonDateInput', {
    staticClass: "min-width",
    attrs: {
      "outlined": "",
      "dense": "",
      "clearable": "",
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.EndTo).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.EndTo).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.EndTo), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.EndTo).value"
    }
  })], 1)]), _c('v-col', {
    staticClass: "px-1"
  }, [_c('CommonAutocomplete', {
    attrs: {
      "items": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ProfessionalUnitIds).items.value,
      "item-text": "name",
      "item-value": "id",
      "clearable": "",
      "chips": "",
      "deletable-chips": "",
      "small-chips": "",
      "outlined": "",
      "dense": "",
      "multiple": "",
      "loading": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ProfessionalUnitIds).isLoading.value,
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ProfessionalUnitIds).label,
      "show-with-children": _vm.state.profUnitWithChildren,
      "children-toggle": ""
    },
    on: {
      "toggleWithChildren": function toggleWithChildren($event) {
        return _vm.listeners.onToggleProfUnitWithChildren();
      }
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.state.profUnitWithChildren ? _vm.constants.FILTER_FIELD_KEY.ProfessionalUnitIdsWithChildren : _vm.constants.FILTER_FIELD_KEY.ProfessionalUnitIds).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.state.profUnitWithChildren ? _vm.constants.FILTER_FIELD_KEY.ProfessionalUnitIdsWithChildren : _vm.constants.FILTER_FIELD_KEY.ProfessionalUnitIds), "value", $$v);
      },
      expression: "\n          functions.getFilterByKey(\n            state.profUnitWithChildren\n              ? constants.FILTER_FIELD_KEY.ProfessionalUnitIdsWithChildren\n              : constants.FILTER_FIELD_KEY.ProfessionalUnitIds\n          ).value\n        "
    }
  }), _c('CommonAutocomplete', {
    attrs: {
      "items": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ContractualRelationship).items.value,
      "item-text": "name",
      "item-value": "name",
      "clearable": "",
      "chips": "",
      "deletable-chips": "",
      "small-chips": "",
      "outlined": "",
      "dense": "",
      "multiple": "",
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ContractualRelationship).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ContractualRelationship).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ContractualRelationship), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.ContractualRelationship).value"
    }
  })], 1), _c('v-col', [!_vm.withoutPersonDropdown ? _c('CommonAutocomplete', {
    attrs: {
      "items": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.AssignmentProfileIds).items.value,
      "item-text": "name",
      "item-value": "id",
      "clearable": "",
      "chips": "",
      "deletable-chips": "",
      "small-chips": "",
      "outlined": "",
      "dense": "",
      "multiple": "",
      "loading": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.AssignmentProfileIds).isLoading.value,
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.AssignmentProfileIds).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.AssignmentProfileIds).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.AssignmentProfileIds), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.AssignmentProfileIds).value"
    }
  }) : _vm._e(), _c('CommonSwitchFilter', {
    staticClass: "mb-3 mt-2 pt-0 ml-2",
    attrs: {
      "value": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Active).value,
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Active).label,
      "inset": "",
      "with-date": true
    },
    on: {
      "change": function change($event) {
        _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Active).value = $event;
      }
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }