var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-switch', _vm._g(_vm._b({
    attrs: {
      "input-value": _vm.value,
      "true-value": _vm.functions.formatTrueValue(),
      "false-value": null
    }
  }, 'v-switch', _vm.$attrs, false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }