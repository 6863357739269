
import { defineComponent, reactive } from '@vue/composition-api'

export default defineComponent({
  name: 'CommonSwitchFilter',
  props: {
    value: {
      type: [String, Boolean],
      default: null,
    },
    withDate: {
      type: Boolean,
      default: false,
    },
  },
  setup: (props) => {
    function formatTrueValue() {
      return props.withDate ? new Date().toISOString().split('T')[0] : true
    }

    return reactive({
      functions: {
        formatTrueValue,
      },
    })
  },
})
